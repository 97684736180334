import axios from "axios";
import CONSTANTS from "../view/const/constants";
import { v4 as uuidv4 } from "uuid";
// axios.defaults.baseURL = CONSTANTS.API_CONFIG.BASE_URL;
axios.interceptors.request.use(function (config) {
    if (localStorage.getItem(CONSTANTS.TOKEN_STORE_KEY) != null) {
        config.headers["session-id"] = localStorage.getItem(
            CONSTANTS.TOKEN_STORE_KEY
        );
    }
    return config;
});
axios.interceptors.response.use((response) => {
    if (localStorage.getItem(CONSTANTS.TOKEN_STORE_KEY) === null) {
        localStorage.setItem(
            CONSTANTS.TOKEN_STORE_KEY,
            response.headers.session_id
        );
    }
    return response;
});
export const get = (url) => {
    return axios.get(url);
};
export const post = (url, data) => {
    return axios.post(url, data);
};
// export default {
//     get:get,
//     post:post
// }

export let APIs = {
    CHAT_API: "/api/v1/chat-llm-interface/chat",
    CHAT_HISTORY_API: "/api/v1/chat-llm-interface/get_user_chat",
    BOT_RESPONSE_BUTTON_API: "",
};
