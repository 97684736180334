import React from "react";
import "./BotButton.css";
import "@fontsource/source-sans-pro";
import { APIs } from "../../../../../utils/ApiUtils";

function BotButton({
    id,
    label,
    search_parameters,
    onChatSendBtnClick,
    clickedButtonID,
    setClickedButtonID,
}) {
    let classes = "bot-response-button ";
    if (clickedButtonID) {
        if (clickedButtonID === id) {
            classes += "bot-response-clicked";
        } else {
            classes += "bot-response-disable";
        }
    } else {
        classes += "bot-response-enable";
    }
    const buttonClickHandler = (event) => {
        let clickedElement = event.target;

        if (clickedElement.classList.contains("bot-response-enable")) {
            document
                .querySelectorAll(".bot-response-button")
                .forEach((item) => {
                    item.classList.remove("bot-response-enable");
                    item.classList.add("bot-response-disable");
                });

            clickedElement.classList.remove("bot-response-disable");
            clickedElement.classList.add("bot-response-clicked");

            if (label.toLowerCase().includes("filter")) {
                onChatSendBtnClick(
                    APIs.CHAT_API,
                    {
                        endpoint: "query_endpoint",
                        query: "What additional details can help filter the search?"
                    },
                    false
                );
            } else {
                console.log(search_parameters)
                console.log(label)
                search_parameters["endpoint"] = "get_obits"
                onChatSendBtnClick(
                    APIs.BOT_RESPONSE_BUTTON_API,
                    search_parameters,
                    false
                );
            }
            setClickedButtonID(id);
        }
    };
    return (
        <button
            id={id}
            className={classes}
            onClick={buttonClickHandler}
            disabled={clickedButtonID ? true : false}
        >
            {label}
        </button>
    );
}

export default BotButton;
