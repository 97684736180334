import React from "react";
import "./BotMessage.css";
import "@fontsource/source-sans-pro";
import parse from "html-react-parser";

function BotMessage({ msg }) {
    return (
        <div className="bot-message-outer-div">
            <div className="bot-message-inner-div">
                <p className="bot-message-p">{parse(msg)}</p>
            </div>
        </div>
    );
}

export default BotMessage;
