import { Link } from "react-router-dom";
import CONSTANTS from "../const/constants";
import { Container, Button, lightColors } from "react-floating-action-button";
import Chat from "../../Chat";
import { useRef } from "react";
import sympathy from "../../sympathy.png";

function Home() {
    const popChat = useRef(null);

    const toggle = () => {
        if (popChat.current) {
            if (popChat.current.style.display === "none") {
                popChat.current.style.display = "block";
            } else {
                popChat.current.style.display = "none";
            }
        }
    };
    const BASE_URL = "http://10.2.153.196:8888";
    const CHAT_API = "/api/v1/chat-llm-interface/chat";
    const CHAT_HISTORY_API = "/api/v1/chat-llm-interface/get_user_chat";

    const colorTheme = {
        color: "#B49561",
        bot: {
            background: "#FFF",
            text: "#000",
        },
        user: {
            background: "#B49561",
            text: "#FFF",
        },
    };

    return (
        <div className="home">
            {/* <video autoPlay muted loop>
                <source src="https://framerusercontent.com/assets/66vNMhclrqqwkhygabaNo1v7sg.mp4"></source>
            </video>
            <div className="flex items-center justify-center home-content">
                <p className="home-txt">How can we help you?</p>
            </div>
            <div className="flex justify-center space-x-10 mt-10 home-content">
                <Link
                    to={"/chat/find-someone"}
                    className="rounded-full home-page-btn home-shadow inline-flex items-center justify-center"
                >
                    <img
                        className="home-btn-icon"
                        src={process.env.PUBLIC_URL + "/glass-icon.svg"}
                    ></img>
                    <span style={{ marginLeft: "5px" }}>
                        {CONSTANTS.chatTypeWiseTitle["find-someone"]}
                    </span>
                </Link>
    </div>*/}
            <div>
                {/* <embed
                    src="https://sympathy.legacy.com/en-us/funeral-flowers/gallery/"
                    // src="https://www.google.com/"
                    style={{ width: "100vw", height: "100vh" }}
                /> */}
                <img
                    src={sympathy}
                    alt="sympathy legacy"
                    style={{ width: "100vw", height: "100vh" }}
                ></img>
            </div>
            <div className="chat-boat-popup" ref={popChat}>
                <Chat
                    popup={true}
                    title="Legacy's Obituary Finder"
                    colorTheme={colorTheme}
                    showHistory={false}
                    historyAPIEndpoint={BASE_URL + CHAT_HISTORY_API}
                    chatBotAPIEndpoint={BASE_URL + CHAT_API}
                    BotButtonsAPIEndpoint={BASE_URL + CHAT_API}
                />
            </div>

            <Container className="chat-boat-floating-icon-cointainer">
                <Button
                    className="chat-boat-floating-icon"
                    icon="fa fa-regular fa-comment"
                    rotate={false}
                    onClick={toggle}
                    styles={{
                        backgroundColor: "#B49561",
                        color: lightColors.white,
                    }}
                />
            </Container>
        </div>
    );
}
export default Home;
