import React from "react";
import "./UserResponse.css";
import parse from "html-react-parser";
// import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/600.css";

function UserResponse(props) {
    const user_response_inner_div_style = {
        backgroundColor: props.colorTheme.user.background,
        color: props.colorTheme.user.text,
    };
    return (
        <div className="user-response-outer-div">
            <div
                className="user-response-inner-div"
                style={user_response_inner_div_style}
            >
                <div className="user-response-div">
                    <p className="user-response-p">{parse(props.data.text)}</p>
                </div>
            </div>
        </div>
    );
}

export default UserResponse;
