import { useState } from "react";
import { APIs } from "../../../../utils/ApiUtils";
import CONSTANTS from "../../../const/constants";
import "./ChatFooter.css";

function ChatFooter(props) {
    const [state, setState] = useState({
        inputValue: "",
    });

    const sendBtnClick = () => {
        if (
            props.onChatSendBtnClick &&
            state.inputValue.trim().length > 0 &&
            props.disabledInput == false
        ) {
            props.onChatSendBtnClick(APIs.CHAT_API, {
                query: state.inputValue,
            });
            setState({
                inputValue: "",
            });
            CONSTANTS.scrollChatBody();
        }
    };

    let chat_footer_inner_div_style = {};

    if (props.popup) {
        chat_footer_inner_div_style.borderBottomLeftRadius = "30px";
        chat_footer_inner_div_style.borderBottomRightRadius = "30px";
    } else {
        chat_footer_inner_div_style.borderBottomLeftRadius = "0px";
        chat_footer_inner_div_style.borderBottomRightRadius = "0px";
    }
    return (
        <div className="chat-footer-container-div">
            <div
                className="chat-footer-inner-div"
                style={chat_footer_inner_div_style}
            >
                <div className="chat-footer-input-outer-div">
                    <div className="chat-footer-input-inner-div">
                        <div className="chat-footer-input-div">
                            <input
                                placeholder="Reply to Legacy"
                                className="chat-footer-input"
                                type="text"
                                value={state.inputValue}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") sendBtnClick();
                                }}
                                onChange={(event) => {
                                    if (props.disabledInput == true) return;
                                    setState({
                                        inputValue: event.target.value,
                                    });
                                }}
                            ></input>
                        </div>
                    </div>
                    <button
                        className="send-button"
                        onClick={() => {
                            sendBtnClick();
                        }}
                        type="button"
                        style={{
                            opacity: props.disabledInput ? "0.5" : "1",
                            cursor: props.disabledInput ? "auto" : "pointer",
                            backgroundColor: props.colorTheme.color,
                        }}
                    >
                        <div className="send-button-div">
                            <img
                                className="mic-icon"
                                src={"/icon-back-arrow.svg"}
                            ></img>
                        </div>
                    </button>
                </div>
                {!props.popup && (
                    <div className="navigate-outer-div">
                        <div className="navigate-inner-div">
                            <p className="navigate-p">
                                <a
                                    className="navigate-a"
                                    href={`https://www.legacy.com/obituaries/search?firstName=${
                                        props.firstName ? props.firstName : ""
                                    }&lastName=${
                                        props.lastName ? props.lastName : ""
                                    }&dateRange=alltime`}
                                >
                                    Use Traditional Search Instead
                                </a>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default ChatFooter;
