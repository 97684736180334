import "@fontsource/pt-serif";
import "@fontsource/pt-serif/400.css";
import "./ChatHeader.css";

function ChatHeader({ popup, title, colorTheme }) {
    let chat_header_outer_div_style = {};
    let chat_header_content_style = {};
    if (popup) {
        chat_header_outer_div_style.padding = "1.5rem";
        chat_header_outer_div_style.backgroundColor = colorTheme.color;
        chat_header_outer_div_style.borderTopLeftRadius = "30px";
        chat_header_outer_div_style.borderTopRightRadius = "30px";

        chat_header_content_style.fontSize = "1.5rem";
        chat_header_content_style.lineHeight = "2rem";
        chat_header_content_style.fontWeight = "700";
        chat_header_content_style.color = "white";
    } else {
        chat_header_outer_div_style.padding = "32px";
        chat_header_outer_div_style.backgroundColor =
            "linear-gradient(rgb(250, 250, 250) 88%,rgba(250, 250, 250, 0) 100%)";
        chat_header_outer_div_style.borderTopLeftRadius = "0px";
        chat_header_outer_div_style.borderTopRightRadius = "0px";

        chat_header_content_style.fontSize = "28px";
        chat_header_content_style.lineHeight = "1.5rem";
        chat_header_content_style.fontWeight = "400";
        chat_header_content_style.color = "rgba(0, 20, 30, 1)";
    }
    return (
        <div
            className="chat-header-outer-div"
            style={chat_header_outer_div_style}
        >
            <div className="chat-header-inner-div">
                <p
                    className="chat-header-content"
                    style={chat_header_content_style}
                >
                    {title}
                </p>
            </div>
        </div>
    );
}

export default ChatHeader;
