const CONSTANTS = {
    chatTypeWiseTitle: {
        "estate-planning": "Estate Planning",
        "find-someone": "Find Someone",
    },
    userType: {
        BOT: "AI",
        USER: "Human",
    },
    TOKEN_STORE_KEY: "LEGACY_AI_TOKEN",
    scrollChatBody: () => {
        const chatBodyDiv = document.getElementById("chat-body");
        if (chatBodyDiv) {
            chatBodyDiv.scrollTop = chatBodyDiv.scrollHeight;
        }
    },
};
export default CONSTANTS;
