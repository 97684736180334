import React from "react";
import "./Card.css";
import "@fontsource/pt-serif";
import "@fontsource/source-sans-pro";

function Card({ heading, content, image }) {
    const clickHandler = () => {
        window.open(content.link, "_blank");
    };

    return (
        <div className="card-outer-div">
            <div className="card-inner-div" onClick={clickHandler}>
                <div className="card-content-outer-div">
                    <div className="card-content-inner-div">
                        <div className="card-heading-div">
                            <p className="card-heading-p">{heading}</p>
                        </div>
                        <div className="card-content-div">
                            <p className="card-content-p">
                                {content?.text}...
                                <a
                                    className="card-show-more-a"
                                    target="_blank"
                                    href={content?.link}
                                >
                                    ReadMore
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                {image && image.length > 0 && (
                    <div className="card-image-outer-div">
                        <div className="card-image-div">
                            <img className="card-image" src={image}></img>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
