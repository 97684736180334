import "./App.css";
import Chat from "./Chat";
import Home from "./view/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./view/container/Layout";
import axios from "axios";

function App() {
    // const BASE_URL = "http://0.0.0.0:5001";
    const BASE_URL = "https://lai-prod.legacy.com";
    const CHAT_API = "/api/v1/chat-llm/query-pdf";
    const CHAT_HISTORY_API = "/api/v1/chat-llm/query-pdf";
    axios.defaults.baseURL = BASE_URL;

    // const BASE_URL = "http://10.2.153.196:8888";
    // const CHAT_API = "/api/v1/chat-llm-interface/chat";
    // const CHAT_HISTORY_API = "/api/v1/chat-llm-interface/get_user_chat";
    const colorTheme = {
        color: "#006599",
        bot: {
            background: "#FFF",
            text: "#000",
        },
        user: {
            background: "#006599",
            text: "#FFF",
        },
    };

    return (
        <div className="layout">
            <main className="flex-1 layout-container">
                <BrowserRouter>
                    <Routes>
                        <Route path="" element={<Home />} />
                        <Route
                            path="/chat/:type"
                            element={
                                <Chat
                                    popup={false}
                                    title="Legacy's Obituary Finder"
                                    colorTheme={colorTheme}
                                    showHistory={false}
                                    historyAPIEndpoint={
                                        BASE_URL + CHAT_HISTORY_API
                                    }
                                    chatBotAPIEndpoint={BASE_URL + CHAT_API}
                                    BotButtonsAPIEndpoint={BASE_URL + CHAT_API}
                                />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </main>
        </div>
    );
}

export default App;
